<template>
    <div>

        <div class="wrap p-find-password">
            <layout-header title="비밀번호 찾기" btn-left="logo" btn-right="close"></layout-header>

            <div class="container">
                <div class="content">
                    <div class="find-form">
                        <form>
                            <p class="guide-text">가입한 이메일 주소를 입력해 주세요.</p>
                            <div class="form-row">
                                <label for="email">이메일</label>
                                <input type="text" name="" id="email" class="inp-member" v-model="coEmailId">
                            </div>
                            <div class="button-area">
                                <button type="button" class="btn-member blue lg" @click="doSearch">비밀번호 변경</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";

export default {
    name: "findPasswd",
    components: {LayoutHeader},
    data() {
        return {
            coEmailId: '',
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        doSearch (e) {

            e.preventDefault()

            const self = this
            this.$eventBus.$emit("startProgress")
            this.axiosCaller.get(this, this.APIs.CO_ACCOUNT + "/doFindPassword", {
                coEmailId: this.coEmailId,
            }, (res) => {
                this.$eventBus.$emit("doneProgress")

                const result = res.data;
                if (result.status === "ok") {
                    const coEmailIdArr = result.coEmailId.split("@");
                    const emailEnc = self.stringUtils.idFilter( coEmailIdArr[0] ) + "@" + coEmailIdArr[1];
                    self.swalUtils.fire("비밀번호 초기화", "가입하신 이메일 " + emailEnc + "로 초기화된 이메일이 발송되었습니다.", "success")
                        .then(() => {
                            self.coEmailId = "";
                            self.userName = "";
                            self.phoneNo = "";

                            self.$router.push({
                                name: "login",
                            })

                        });
                } else {
                    self.swalUtils.gritter(result.status, result.msg, "error", 2000);
                }
            })


        }
    }


}
</script>

<style scoped>

</style>